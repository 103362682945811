$baseSize: 16;
$baseFontSize: #{$baseSize}px;

$fontOutfit: 'Outfit';
$fontRockSalt: 'Rock Salt';

$greenLight: #00BF49;
$green: #00A640;
$greenDark: #008031;

$red: #BF0A00;

$purpleLight: #6D0ABF;
$purple: #420673;
$purpleDark: #330459;

$blueDark: #030333;
$blueNotAsDark: #04044D;

// $turquoiseGray: #3F4D4A;
// $turquoise: #006663;
// $turquoiseLight: #00B3AD;

$baseGray: #ccc;

$textBgDark: #F0F0F0;
$textBgLight: #F5F5F5;
$textLight: #7A7A7A;
$textDark: #606B73;
$textUltraDark: #2A2F33;

$background: #242433;