@import 'npm:normalize.css/normalize.css';
@import 'npm:tippy.js/dist/tippy.css';
// @import 'npm:tippy.js/animations/scale.css';
@import 'npm:tippy.js/animations/scale-subtle.css';


@font-face {
	font-display: swap;
	font-family: $fontOutfit;
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/outfit-v11-latin-300.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: $fontOutfit;
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/outfit-v11-latin-500.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: $fontRockSalt;
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/rock-salt-v18-latin-regular.woff2') format('woff2');
}


[x-cloak] {
	display: none !important;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	font-family: $fontOutfit;
	font-weight: 300;
	font-size: $baseFontSize;
	line-height: 1.4;
	background-color: #242433;
}

body {
	position: relative;
	display: flex;
	flex-direction: column;
	// place-content: center;
	place-items: center;
}