.background {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: 10;

	&__matter {
		width: 100%;
		height: 100%;
	}
}